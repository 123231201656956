class Installment {
  calculate(price = 1000, month = 3){
    let result;

    switch (month) {
      case 3:
        result = price + (price / 100) * 11;
        break;
      case 6:
        result = price + (price / 100) * 22;
        break;
      case 9:
        result = price + (price / 100) * 33;
        break;
      case 12:
        result = price + (price / 100) * 44;
        break;
    }

    return Math.round(result);
  };

  perMonth(price = 1000, month = 3) {
    return Math.round(this.calculate(price, month) / month)
  }
}

export default new Installment();