<template>
  <div class="product-page">
    <header>
      <h1>{{ localize('productPage')['title'] }}</h1>
    </header>

    <section v-if="product !== null" class="container">
      <div class="row mt-80">
        <div class="product-own col-md-6 pr-16">
          <div class="swiper-container gallery-top">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide image"
                v-for="(image, i) in product.formattedImages"
                :key="i"
              >
                <img :src="image" alt="product">
              </div>
            </div>
          </div>

          <div class="swiper-container gallery-thumbs">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(image, index) in product.formattedImages"
                :key="index"
              >
                <img class="images" :src="image" alt="product thumbnail">
              </div>
            </div>
          </div>
        </div>

        <div class="product-data col-md-6 pl-16">
          <h1 class="font-size-40 font-weight-900">{{ product.name }}</h1>
          <span v-for="(category, index) in product.public_categories" :key="index" class="status">
            {{ category.name }}
          </span>
          <!--          <span class="sale">Скидка 25% еще 5 дней</span>-->

          <p class="text-main font-weight-700 font-size-32 mt-28">
            {{ $t('currency', { price: $numberFormat(product.price) } ) }}
          </p>
          <p class="installment">{{ $t('currency', { price: $numberFormat(product.price / 12) }) }} {{ $t('installmentMonth') }}</p>

          <div class="d-flex mt-40">
            <main-button :is-loading="isLoading" @click="showFakeLoader">{{ localize('button')['buy'] }}</main-button>
          </div>
        </div>
      </div>
    </section>

    <section v-if="product !== null" class="container info">
      <div class="row">
        <div class="col-md-8 description">
          <h1 class="font-size-40 font-weight-900">{{ $t('description') }}</h1>
          <p>{{ product.description }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import localize from '../filters/localize.filter';
import MainButton from '../components/buttons/MainButton';

import InstallmentService from '../services/installment.service';
import ProductService from '@/services/product.service';

import Swiper from 'swiper/swiper-bundle.min.js';
import 'swiper/swiper-bundle.min.css';

export default {
  name: 'ProductPage',
  props: ['id'],
  components: {
    MainButton,
  },
  data: () => ({
    product: null,
    galleryThumbs: null,
    isLoading: false
  }),
  computed: {
    perMonth() {
      return InstallmentService.perMonth(this.product.price, 12);
    },
  },
  methods: {
    localize,
    showFakeLoader() {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 1500)
    }
  },
  created() {
    this.product = ProductService.getOne(this.id)
  },
  mounted() {
    this.galleryThumbs = new Swiper('.gallery-thumbs', {
      spaceBetween: 32,
      slidesPerView: 6,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 2.5,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        576: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 4,
          spaceBetween: 32,
        },
        992: {
          slidesPerView: 5,
          spaceBetween: 32,
        },
        1200: {
          slidesPerView: 6,
          spaceBetween: 32,
        },
      },
    });
    new Swiper('.gallery-top', {
      spaceBetween: 10,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: { swiper: this.galleryThumbs },
    });
  },
};
</script>

<style lang="scss" scoped>
  @mixin status-sale {
    border-radius: 8px;
    padding: 7px 12px;
    color: $white;
    font-size: 14px;
    font-weight: 700;
  }

  .product-page {
    padding-bottom: 100px;

    header {
      text-align: center;
      padding: 56px 0;
      background: $grey url('../assets/icons/Vector.svg') no-repeat calc(50% - 120px) 37px;

      h1 {
        font-weight: 900;
        font-size: 40px;
        line-height: 40px;
        color: $black;
      }
    }

    section {
      .product-own {
        .image {
          /*display: flex;*/
          /*justify-content: center;*/
          border-radius: 8px;
          border: 1px solid #E2E2E2;
        }

        .gallery-thumbs {
          margin-top: 16px;

          .swiper-slide {
            cursor: pointer;
            border-radius: 8px;
            border: 1px solid #E2E2E2;

            img {
              mix-blend-mode: multiply;
            }
          }

          .swiper-slide-thumb-active {
            background: rgba(249, 249, 249, 1);
            border: 1px solid $main;
            border-radius: 8px;
          }
        }

        .thumbnails {
          margin-top: 23px;
          display: flex;

          .images {
            width: 72px;
            height: 72px;
            margin-right: 32px;
            border-radius: 8px;
            border: 1px solid #E2E2E2;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .product-data {
        h1 {
          margin-bottom: 12px;

          @media (max-width: $mobile) {
            margin-top: 24px;
          }
        }

        button {
          width: auto;
          padding: 14px 28px;
          @media (max-width: $mobile) {
            width: 100%;
          }
        }

        .status {
          background-color: $success;
          margin-right: 8px;
          @include status-sale
        }

        .sale {
          background-color: $main;
          @include status-sale
        }

        p.installment {
          color: $muted;
          // opacity: .4;
        }
      }
    }

    section.info {
      margin-bottom: 250px;

      .description {
        margin-top: 100px;

        p {
          margin-top: 24px;
        }
      }

      .characteristics {
        margin-top: 150px;

        ul.striped-list {
          margin: 24px 0 0 0;
          list-style-type: none;
          padding: 0;
        }

        ul.striped-list > li {
          padding: 11px 16px;
        }

        ul.striped-list > li:nth-of-type(odd) {
          background-color: $grey;
        }

        ul.striped-list > li:last-child {
          border-bottom: none;
        }

      }
    }
  }
</style>
